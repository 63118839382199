import Vue from 'vue'
import axios from 'axios'
import myConfig, { TOKEN_KEY } from '../config/index'
import { GetToken } from '../utils/auth'
import { Loading, Message } from 'element-ui'

Vue.use(Loading)
// 创建axios实例
const service = axios.create({
  baseURL: myConfig.NETAPIURL,
  withCredentials: false,
  // 超时
  timeout: 1000 * 60 * 5
})
// service.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
let _requestLoading = ''
// request拦截器
service.interceptors.request.use(
  (config) => {
    try {
      if (
        (config.data && config.data.loading) ||
        (config.params && config.params.loading)
      ) {
        _requestLoading = Loading.service({
          lock: true,
          text: 'Loading',
          background: 'rgba(255, 255, 255, 0.7)'
        })
      }
    } catch (error) {}
    // 是否需要设置 token
    // 让每个请求携带自定义token 请根据实际情况自行修改
    if (GetToken()) {
      config.headers[TOKEN_KEY] = GetToken() || ''
    }
    // get请求映射params参数
    if (config.method === 'get' && config.params) {
      let url = config.url + '?'
      for (const propName of Object.keys(config.params)) {
        const value = config.params[propName]
        var part = encodeURIComponent(propName) + '='
        if (value !== null && typeof value !== 'undefined') {
          if (typeof value === 'object') {
            for (const key of Object.keys(value)) {
              const params = propName + '[' + key + ']'
              var subPart = encodeURIComponent(params) + '='
              url += subPart + encodeURIComponent(value[key]) + '&'
            }
          } else {
            url += part + encodeURIComponent(value) + '&'
          }
        }
      }
      url = url.slice(0, -1)
      config.params = {}
      config.url = url
    }
    return config
  },
  (error) => {
    console.log(error)
    Promise.reject(error)
  }
)
// service.defaults.retry = 2
// service.defaults.retryDelay = 1000
// 响应拦截器
service.interceptors.response.use(
  (res) => {
    if (_requestLoading) {
      _requestLoading.close()
    }
    if (res.status === 200) {
      if (res.config.responseType === 'blob') {
        // 文件流，直接 return
        return res
      } else {
        // 获取返回代码
        const state = res.data.state || res.data.code
        // 获取错误信息
        const msg = res.data.msg || res.data.message
        if (state) {
          return res
        } else {
          if (!state && res.data.code == 'InvalidToken') {
            // 告诉父页面需要重新登录
            localStorage.removeItem(TOKEN_KEY)
            // 登录已过期
            // localStorage.removeItem(TOKEN_KEY)
            // location.href =
            //   Config.ssoUrl + '/login/?url=' + encodeURIComponent(Config.siteUrl)
          } else {
            console.log('err msg:', msg)
            Message.error({
              message: msg
            })
            return Promise.reject(res)
          }
        }
      }
    } else {
      Message.error(res.data.message || '网络异常')
      return Promise.reject(res)
    }
  },
  (error) => {
    const errorStatus = error.response?.status
    if (errorStatus === 666 || errorStatus === 401) {
      // 删除token
      localStorage.removeItem(TOKEN_KEY)
      // location.href =
      //   Config.ssoUrl + '/login/?url=' + encodeURIComponent(Config.siteUrl)
    }
    var config = error.config
    // If config does not exist or the retry option is not set, reject
    if (!config || !config.retry) return Promise.reject(error)
    // Set the variable for keeping track of the retry count
    config.__retryCount = config.__retryCount || 0
    // Check if we've maxed out the total number of retries
    if (config.__retryCount >= config.retry) {
      // Reject with the error
      return Promise.reject(error)
    }
    // Increase the retry count
    config.__retryCount += 1
    console.log(config.url + '自动重试第' + config.__retryCount + '次')
    // Create new promise to handle exponential backoff
    var backoff = new Promise(function (resolve) {
      setTimeout(function () {
        resolve()
      }, config.retryDelay || 1)
    })
    // Return the promise in which recalls axios to retry the request
    return backoff.then(function () {
      return service(config)
    })
  }
)

export const get = (url, params = {}) => {
  return new Promise((resolve, reject) => {
    service.get(url, { params }).then(
      (response) => {
        resolve(response.data)
      },
      (err) => {
        reject(err)
      }
    )
  })
}
export const post = (url, params = {}) => {
  return new Promise((resolve, reject) => {
    service.post(url, params).then(
      (response) => {
        resolve(response.data)
      },
      (err) => {
        reject(err)
      }
    )
  })
}
export const netpost = (url, params = {}) => {
  return new Promise((resolve, reject) => {
    service({
      method: 'POST',
      url: url,
      data: params,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }).then(
      (response) => {
        resolve(response.data)
      },
      (err) => {
        reject(err)
      }
    )
  })
}
export const netdonwloadexcel = (url, params = {}) => {
  return new Promise((resolve, reject) => {
    _requestLoading = Loading.service({
      lock: true,
      text: '正在导出...',
      background: 'rgba(255, 255, 255, 0.7)'
    })
    axios({
      baseURL: process.env.NODE_ENV === 'sit' ? '' : myConfig.NETAPIURL,
      url: url, //用于请求的服务器 URL
      data: params, //必须是一个无格式对象(plain object)或 URLSearchParams 对象
      method: 'POST', //请求时使用的方法,get默认
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      responseType: 'blob' //表示服务器响应的数据类型，可以是 'arraybuffer', 'blob', 'document', 'json'(默认), 'text', 'stream'
    }).then(
      (response) => {
        _requestLoading.close()
        resolve(response.data)
      },
      (err) => {
        reject(err)
      }
    )
  })
}
export const netdownload = (url, params = {}) => {
  _requestLoading = Loading.service({
    lock: true,
    text: '正在导出...',
    background: 'rgba(255, 255, 255, 0.7)'
  })
  axios({
    baseURL: process.env.NODE_ENV === 'sit' ? '' : myConfig.NETAPIURL,
    url: url, //用于请求的服务器 URL
    data: params, //必须是一个无格式对象(plain object)或 URLSearchParams 对象
    method: 'POST', //请求时使用的方法,get默认
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    responseType: 'blob' //表示服务器响应的数据类型，可以是 'arraybuffer', 'blob', 'document', 'json'(默认), 'text', 'stream'
  }).then((res) => {
    console.log(res, 'download data')
    _requestLoading.close()
    let data = res.data
    let fileName = '提单号数据'
    if (typeof window.navigator.msSaveBlob !== 'undefined') {
      window.navigator.msSaveBlob(
        new Blob([data], { type: 'application/vnd.ms-excel' }),
        fileName + '.xlsx'
      )
    } else {
      let url = window.URL.createObjectURL(
        new Blob([data], { type: 'application/vnd.ms-excel' })
      )
      let link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      link.setAttribute('download', fileName + '.xlsx')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link) //下载完成移除元素
      window.URL.revokeObjectURL(url) //释放掉blob对象
    }
  })
}

// export const upload = (url, file) => {
//   const formData = new FormData()
//   formData.append('file', file)
//   return service.post(url, formData, {
//     headers: {
//       'Content-Type': 'multipart/form-data'
//     }
//   })
// }
export const upload = (url, params) => {
  const formData = new FormData()
  for (const [key, value] of Object.entries(params)) {
    if (key === 'fileList') {
      for (const file of value) {
        formData.append('file', file)
      }
    } else {
      formData.append(key, value)
    }
  }
  return service.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}
export const downloadFile = (response) => {
  const blob = response.data
  const reader = new FileReader()
  reader.readAsDataURL(blob)
  reader.onload = (e) => {
    const a = document.createElement('a')
    a.style.display = 'none'
    a.download = 'fileName'
    a.href = e.target.result
    a.setAttribute('download', 'generator.zip')
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }
}

export default service
